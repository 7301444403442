
import React, { useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { cn } from '@/shadcn/utils'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { Loader2, Minus } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button'
import { ScoreNumber } from '@/shadcn/motion/score-number'
import { getWinnerLoserByScore } from '@/models/match'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'
import { createGlobalState } from 'react-use'

const useGlobalHoverId = createGlobalState("")

const ScoreValue = ({ match, reload, score, index, disabled }) => {
  const [loading, setLoading] = useState(false)
  const [initialY, setInitialY] = useState(10)

  const markScore = async ({ index, value }) => {
    if (loading !== false || disabled) {
      return
    }
    setLoading(true)
    setInitialY(value > 0 ? 10 : -10)
    const before = getWinnerLoserByScore(match.scoreboard)
    const newScoreboard = await match.moveScore({ index, value })
    const after = getWinnerLoserByScore(newScoreboard)
    if (after?.winner?.playerId && (before?.winner?.playerId !== after?.winner?.playerId)) {
      await match.playersPromote() // 节流，很多情况下重新算晋级没有必要
    }
    if (match.stage !== "finished") {
      await match.markStage("finished")
    }
    await reload()
    setLoading(false)

  }
  return (
    <>
      <Button
        size="small"
        variant="ring"
        onClick={() => markScore({ index, value: -1 })}
        className={cn("opacity-0 absolute transition-all right-9 group-hover/1:opacity-60 group-hover/1:hover:opacity-100 group-hover/2:opacity-60 group-hover/2:hover:opacity-100", {
          hidden: disabled,
        })}
      >
        <Minus />
      </Button>

      <div
        onClick={() => markScore({ index, value: 1 })}
        className={cn("text-foreground ml-auto w-full h-full flex justify-center items-center flex-shrink-0",
          {
            "hover:opacity-60": disabled === false,
          })}>
        {loading && (
          <Loader2 className='w-4 h-4 animate-spin' />
        )}
        {loading === false && score?.playerId && (
          <ScoreNumber
            loading={loading}
            key={`${score?.value}_${score?.playerId}_${match.id}`}
            initialY={initialY}
            number={score?.value}
          />
        )}
      </div>
    </>
  )
}

export function SeatDraggable({ player, match, className, reload, seatIndex, dragEndLoading, matching }) {
  const [p1Dependents, p2Dependents] = match?.tournamentProperties?.dependents || []
  const [p1Score, p2Score] = match?.scoreboard || []
  const { winner } = getWinnerLoserByScore(match?.scoreboard)
  const p1Win = p1Score?.playerId && p1Score?.playerId === winner?.playerId
  const p2Win = p2Score?.playerId && p2Score?.playerId === winner?.playerId

  const stageOfEvent2 = match.controller.event?.stage === "2-CONFIRM-SEAT"
  const stageOfEvent3 = match.controller.event?.stage === "3-INPROGRESS"
  const stageOfEvent4 = match.controller.event?.stage === "4-FINISHED"
  const isSwiss = match.controller.event?.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1
  const disabledForSwiss = isSwiss && (!p1Score || !p2Score) // 瑞士轮的轮空禁止操作
  const seatSwitching = (dragEndLoading?.aMatch?.id === match.id || dragEndLoading?.bMatch?.id === match.id) && ((seatIndex === 1 && p1Dependents === undefined) || (seatIndex === 2 && p2Dependents === undefined))
  const someWinnerUsed = (seatIndex === 1 && p1Win) || (seatIndex === 2 && p2Win)

  let [hoverPlayer, setHoverPlayer] = useGlobalHoverId()
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `drag_${player?.name}_${player?.id}_${match?.id}`,
    data: { match, player },
    disabled: !matching || !player?.name || stageOfEvent3 || stageOfEvent4,
  })
  const style = { transform: CSS.Translate.toString(transform) }


  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
      className={cn(className,
        'bg-secondary h-[30px] flex items-center w-[200px] z-20 transition-colors',
        {
          'z-[999] absolute border border-foreground opacity-80': isDragging,
          'text-secondary-foreground/40': !player?.name,
          "group/1": seatIndex === 1,
          "group/2": seatIndex === 2,
          "cursor-default": stageOfEvent4,
          "underline underline-offset-2 bg-yellow-300 dark:bg-yellow-700": hoverPlayer && ((seatIndex === 1 && hoverPlayer === p1Score?.playerId) || (seatIndex === 2 && hoverPlayer === p2Score?.playerId)),
        },
      )}
      onMouseEnter={() => { setHoverPlayer(seatIndex === 1 ? p1Score?.playerId : p2Score?.playerId) }}
      onMouseLeave={() => { setHoverPlayer("") }}
    >

      {!player && seatSwitching && (
        <div className='truncate font-medium text-sm pl-2'>正在调整席位</div>
      )}

      {player?.name && (
        <>
          <div className='w-8 flex justify-center items-center flex-shrink-0'>
            <PlayerAvatar player={player} className="w-4 h-4" />
          </div>
          <div className='truncate font-medium text-sm'>{player?.name}</div>
        </>
      )}
      {!player && seatIndex === 1 && (
        <div className='text-xs p-2 font-number'>{p1Dependents?.toUpperCase()}</div>
      )}
      {!player && seatIndex === 2 && (
        <div className='text-xs p-2 font-number'>{p2Dependents?.toUpperCase()}</div>
      )}

      <div className={cn('ml-auto h-full w-8  flex items-center justify-center font-number transition-all', {
        "rounded-tr-lg": seatIndex === 1,
        "bg-winner": someWinnerUsed,
        "bg-[#c7c7c7] dark:bg-[#4c4c4c]": !someWinnerUsed,
        "rounded-br-lg": seatIndex === 2,
        "hidden": !player || stageOfEvent2 || disabledForSwiss,
      })}>
        {seatIndex === 1 && (
          <ScoreValue
            match={match}
            reload={reload}
            score={p1Score}
            index={0}
            disabled={stageOfEvent4 || !matching} />
        )}
        {seatIndex === 2 && (
          <ScoreValue
            match={match}
            reload={reload}
            score={p2Score}
            index={1}
            disabled={stageOfEvent4 || !matching} />
        )}
      </div>
    </div>
  )
}
