import { useContext } from 'react'
import { Form, FormControl, FormField, FormItem, FormDescription, FormMessage } from '@/shadcn/components/ui/form'
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { AccountContext } from '@/components/laf-auth/account'
import * as z from "zod"
import { Button } from '@/shadcn/components/ui/button'
import { Input } from '@/shadcn/components/ui/input'
import { PasswordInput } from '@/shadcn/components/_password-input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

export function LoginByPasswordForm({ callback }) {
  const navigate = useNavigate()

  const formSchema = z.object({
    username: z.string().min(2, { message: "ID must be at least 2 characters." }),
    password: z.string().min(2, { message: "Password must be at least 2 characters." }),
  })
  const { login } = useContext(AccountContext)

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      terms: true,
    },
  })

  const onSubmit = async (values) => {
    let { username, password } = values
    let { code, message } = await login(username, password) // 登录成功之后什么都不用做, Account 组件会自动更新
    if (code === 1) {
      toast("Login Successful", { closeButton: true })
      callback()
    } else {
      toast.message('登录失败', {
        description: message,
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 bg-secondary p-4 rounded-lg min-h-80">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="ID" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <PasswordInput placeholder="密码" {...field} />
              </FormControl>
              <FormDescription className="float-right">
                <Button variant="ghost" onClick={(e) => {
                  e.preventDefault()
                  navigate("/account/forget-password")
                }}>
                  忘记密码
                </Button>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* <FormField
          control={form.control}
          name="terms"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-2 space-y-0 rounded-md pl-2">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="flex items-center">
                  同意
                  <a href="" className='flex mx-2' target="_blank">
                    用户协议 <LinkIcon className='w-4 h-4' />
                  </a>
                  和
                  <a href="" className='flex mx-2' target="_blank">
                    隐私政策 <LinkIcon className='w-4 h-4' />
                  </a>
                </FormLabel>
                <FormDescription className="flex items-center">
                  了解为何需要登录，以及登录可使用的功能。
                  <ArrowRight className='w-4 h-4' />
                </FormDescription>
              </div>
            </FormItem>
          )}
        /> */}
        <Button type="submit" className="w-full mt-auto">登录</Button>
      </form>
    </Form>
  )
}
