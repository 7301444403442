import { useEffect, useRef, useState } from "react"
import { useScroll } from "react-use"
import { cn } from "@/shadcn/utils"
import { cloneDeep } from 'lodash'
// import { MatchSmallCard } from "@/pages/events/detail/stages/graph-blocks/match-small-card"
import { getWinnerLoserByScore } from "@/models/match"
import { genLinkLineContainer } from "./lines-generator"
import { Unit } from "@/pages/events/match-card-unit/unit"


const spacer = (direction, number) => {
  if (direction === "x") {
    return number * 290
  } else if (direction === "y") {
    return number * 100
  }
}

const singleElimination = (matches, start) => {
  let interval = 1
  // step.1 初始化 x 坐标和其他字段
  let initMatches = matches.map((i, index) => {
    let im = i.tournamentProperties
    i.x = (im.round - 1) * interval + start.x
    i.y = 0
    i.linkIds = []
    return i
  })

  // step.2 基础的 y 坐标，在后面的代码中作为基础会稍作修改
  let rounds = [...new Set(initMatches.map(i => i.tournamentProperties.round))]
  let ms = rounds.map(round => initMatches.filter(i => i.tournamentProperties.round === round))
  ms.forEach((roundMatches) => {
    roundMatches.forEach((i, index) => {
      i.y = index * interval + start.y
    })
  })

  // step.3 根据基础 y 坐标调整每一轮的 y 坐标
  for (let i = 0; i < ms.length; i++) {
    // 将当前轮和上一轮做比较，一次只调整数量较少的那一轮的位置，被调整的轮次称为“调整轮”，不调整的轮次称为“对轮”
    // 调整规则：
    // 0.依赖关系要考虑方向性，这个方向性通过 strategy 字符串来构成两种不同的逻辑
    // 1.如果该比赛正好是对轮某个比赛 M 的依赖，且对轮中只有一个依赖，那么这个对象将对齐 M 的 .y
    // 2.如果该比赛正好是对轮某个比赛 M1 M2 的依赖，且对轮中有两个依赖，那么这个对象将对齐 M1 M2 的 .y 平均值
    if (i === 0) { continue }
    let thisRound = ms[i]
    let lastRound = ms[i - 1]
    if (thisRound.some(i => i.tournamentProperties.reset === true)) {
      // Reset 特殊处理
      thisRound[0].y = lastRound[0].y
      if (thisRound[1]) {
        thisRound[1].y = lastRound[0].y
        thisRound[1].x += 1
      }
      continue
    }

    let strategy = thisRound.length > lastRound.length ? "adjustLast" : "adjustThis"
    // 策略1 adjustSame，相等的情况下，直接对齐
    if (thisRound.length === lastRound.length) {
      thisRound.forEach((m, idx) => {
        m.y = lastRound[idx].y
      })
      continue
    }
    // 策略2 adjustThis，调整本轮
    if (strategy === "adjustThis") {
      thisRound.forEach(adjustMatch => {
        // 调整自己这一轮，看自己这一轮的依赖项目
        let lastReference = adjustMatch.tournamentProperties.dependents.map(i => {
          let [number] = i.split(' ')
          let found = lastRound.find(j => j.tournamentProperties.number === Number(number))
          return found
        }).filter(i => i !== undefined)
        if (lastReference.length === 1) {
          adjustMatch.y = lastReference[0].y
        }
        if (lastReference.length === 2) {
          adjustMatch.y = (lastReference[0].y + lastReference[1].y) / 2
        }
      })
    }
    // 策略3 adjustLast，调整上一轮
    if (strategy === "adjustLast") {
      lastRound.forEach(adjustMatch => {
        // 看看本轮中有哪些依赖了 adjustMatch
        let dependency = thisRound.filter(m => {
          let win = m.tournamentProperties.dependents.includes(`${adjustMatch.tournamentProperties.number} win`)
          let lose = m.tournamentProperties.dependents.includes(`${adjustMatch.tournamentProperties.number} lose`)
          return win || lose
        })
        if (dependency.length === 1) {
          adjustMatch.y = dependency[0].y
        }
        if (dependency.length === 2) {
          adjustMatch.y = (dependency[0].y + dependency[1].y) / 2
        }
      })
    }
  }

  // step.4 遍历所有比赛，查看自己依赖了谁，就将谁的 linkIds 添加为自己
  matches.forEach(m => {
    let noDependents = m.tournamentProperties.dependents.length === 0
    let isReset = m.tournamentProperties.reset !== true
    if (noDependents || isReset) {
      m.tournamentProperties.dependents.forEach(d => {
        let [number] = d.split(' ')
        let found = matches.find(i => i.tournamentProperties.number === Number(number))
        if (found) {
          // found.linkIds.push(m._id)
          found.linkIds.push(m.id)
        }
      })
    }
  })
  return initMatches
}

const doubleElimination = (rawMatches) => {
  let matches = cloneDeep(rawMatches)

  let winGroup = matches.filter(i => i.tournamentProperties.group === 'win')
  let loseGroup = matches.filter(i => i.tournamentProperties.group === 'lose')
  let firstRoundWinGroup = winGroup.filter(i => i.tournamentProperties.round === 1).length
  let maybeSecondGroupCount = winGroup.filter(i => i.tournamentProperties.round === 2).length
  if (maybeSecondGroupCount > firstRoundWinGroup) {
    firstRoundWinGroup = maybeSecondGroupCount
  }

  let wg = singleElimination(winGroup, { x: 0, y: 0 })
  let lg = singleElimination(loseGroup, { x: 0, y: firstRoundWinGroup + 1 })

  let all = [].concat(wg, lg)
  // 胜败组混淆之后，再次对 tournamentProperties.number 做升序
  all.sort((a, b) => {
    return a.tournamentProperties.number - b.tournamentProperties.number
  })
  return all
}

const useNodesLinks = (ms) => {
  let [nodes, setNodes] = useState([])
  let [links, setLinks] = useState([])

  useEffect(() => {
    onGenMatches(ms)
  }, [ms])

  const onGenMatches = async (rawMatches) => {
    let matches = doubleElimination(rawMatches)
    let renderNodes = matches.map(i => {
      let x = spacer("x", i.x)
      let y = spacer("y", i.y)
      i.view = { position: { x: x + 50, y: y + 50 }, size: { w: 200, h: 62 } }
      return i
    })
    setNodes(renderNodes)

    // 根据 render nodes 生成 links，主要是分别读取每一个 linkIds，然后生成一个 link
    // 读取 node 的 linksIds，记录 node 的右边中点坐标，再记录 link 对应 node 的左边中点坐标
    let links = []
    renderNodes.forEach(node => {
      node.linkIds.forEach(linkId => {
        // let targetNode = renderNodes.find(i => i._id === linkId)
        let targetNode = renderNodes.find(i => i.id === linkId)
        let sourceX = node.view.position.x + node.view.size.w
        let sourceY = node.view.position.y + node.view.size.h / 2
        let targetX = targetNode.view.position.x
        let targetY = targetNode.view.position.y + targetNode.view.size.h / 2
        links.push({
          from: { x: sourceX, y: sourceY },
          to: { x: targetX, y: targetY },
        })
      })
    })

    // 如果是双败赛，为 reset 比赛添加 link
    const resetMatch = renderNodes.find(i => i.tournamentProperties.reset === true)
    if (resetMatch) {
      const finalsMatch = renderNodes.find(i => i.number === resetMatch.tournamentProperties.number - 1)
      let sourceX = finalsMatch.view.position.x + finalsMatch.view.size.w
      let sourceY = finalsMatch.view.position.y + finalsMatch.view.size.h / 2
      let targetX = resetMatch.view.position.x
      let targetY = resetMatch.view.position.y + resetMatch.view.size.h / 2

      links.push({
        from: { x: sourceX, y: sourceY },
        to: { x: targetX, y: targetY },
      })
    }


    let lines = links.map(i => {
      let line = genLinkLineContainer(i.from, i.to, "rightAngle") // line, curve, rightAngle
      if (
        isNaN(line.x) ||
        isNaN(line.y) ||
        isNaN(line.width) ||
        isNaN(line.height)
      ) {
        return null
      }
      return line
    }).filter(i => i !== null)

    setLinks(lines)
  }

  return { nodes, links }
}


// 【本组件为纯静态前端组件】
// 接受一组 matches，渲染赛事的对阵表
// 不通过 Event ID 渲染的原因是这样可以静态化，不需要请求数据，就可以在创建阶段纯前端预览
export const getNodesContentSize = (nodes) => {
  if (!nodes) {
    return { width: 300, height: 300 }
  }
  let mostX = Math.max(...nodes.map(i => i.x))
  let mostY = Math.max(...nodes.map(i => i.y))
  return {
    width: spacer("x", mostX + 1) + 60,
    height: spacer("y", mostY + 1) + 160 // 这 160 人工修正，为了操作栏空间
  }
}

export const TournamentGraph = ({ matches, controllerProps, screenFull, draggingProps, dragEndLoading }) => {
  const { nodes, links } = useNodesLinks(matches)
  const scrollRef = useRef(null)
  const [pointerDown, setPointerDown] = useState(false)
  const { x, y } = useScroll(scrollRef)
  let { width, height } = getNodesContentSize(nodes)
  let cw = scrollRef.current?.clientWidth
  let ch = scrollRef.current?.clientHeight
  let allowScroll = cw < width || ch < height
  return (
    <div
      ref={scrollRef}
      // 这个方案会导致子元素事件无法响应
      // onPointerDownCapture={(e) => {
      //   e.stopPropagation()
      //   setPointerDown(true)
      // }}
      data-draggable={"allow"}
      onPointerDown={(e) => {
        if (e.target.dataset.draggable === "allow") {
          setPointerDown(true)
        }
      }}
      onPointerUp={() => setPointerDown(false)}
      onPointerMove={(e) => {
        if (pointerDown === true) {
          scrollRef.current.scrollTo(x - e.movementX, y - e.movementY)
        }
      }}
      onPointerLeave={() => setPointerDown(false)}
      className={cn("overflow-scroll relative h-full w-full rounded-lg scrollbars-hidden bg-background no-scrollbar",
        {
          "cursor-grabbing": allowScroll && pointerDown,
          "cursor-grab": allowScroll && !pointerDown,
          "min-h-[calc(100dvh_-_6rem)]": screenFull,
        }
      )}
    >
      <div data-draggable={"allow"} style={{ width: `${width}px`, height: `${height}px` }}>
        {nodes.map((i, index) => {
          // reset 特殊显示
          if (i.tournamentProperties.reset === true) {
            const winnerFinal = nodes[nodes.length - 2]
            const { winner } = getWinnerLoserByScore(winnerFinal.scoreboard)
            let noWinner = winnerFinal.stage === "in progress" || winner === null
            let P1WinP2 = winnerFinal.stage === "finished" && (winner?.playerId === winnerFinal?.scoreboard[0]?.playerId)
            if (noWinner || P1WinP2) {
              return (
                <div key={`nodes_reset_${index}_${i.key}`}
                  style={{
                    left: i.view.position.x,
                    top: i.view.position.y,
                    width: i.view.size.w,
                    height: i.view.size.h,
                  }}
                  className="absolute w-[200px] h-[60px] rounded-[5px] p-2 text-sm border flex items-center justify-center">
                  <span className="opacity-80">
                    {noWinner && "总决赛未结束，无 Reset"}
                    {P1WinP2 && "胜者组冠军已获胜，无 Reset"}
                  </span>
                </div>
              )
            }
          }

          // 正常对阵显示
          return (
            <div
              key={`nodes_${index}_${i.key}`}
              className={cn("rounded-lg bg-zinc-200 dark:bg-zinc-500 absolute flex justify-center items-center z-10", {
                "z-[999]": draggingProps?.matchId === i.id,
              })}
              style={{
                left: i.view.position.x,
                top: i.view.position.y,
                width: i.view.size.w,
                height: i.view.size.h,
              }}
            >
              <div key={i.id}
                data-match-id={i.id}
                className={cn(
                  "absolute duration-1000 rounded-sm",
                )}
              >
                <Unit
                  dragEndLoading={dragEndLoading}
                  stage={controllerProps?.stage}
                  match={i}
                  reload={controllerProps?.reload}
                />
              </div>
            </div>
          )
        })}

        {links.map((line, index) => {
          return (
            <svg
              key={`lines_${index}`}
              className="pointer-events-none absolute overflow-visible z-0"
              style={{
                left: line.x,
                top: line.y,
                height: line.height,
                width: line.width,
              }}
            >
              <g
                className="cursor-pointer connect-group"
                focusable="true"
                style={{ pointerEvents: "visibleStroke" }}
              >
                <path
                  d={line.rightAnglePath}
                  className={cn(`connect-path stroke-[4px] stroke-secondary fill-none`)}
                  style={{ strokeLinecap: "round", strokeLinejoin: "round" }}
                />
              </g>
            </svg>
          )
        })}
      </div>
    </div>
  )
}