import React from "react"
import { AnimatePresence, motion } from "framer-motion"

export const ScoreNumber = ({ number, initialY, loading }) => {

  return (
    <AnimatePresence>
      <motion.span

        // key={`${currentKey}`}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          type: "spring",
          stiffness: 400,
          damping: 12,
        }}
        exit={{
          opacity: 0,
          position: "absolute",
        }}
        initial={{ opacity: 0, y: initialY }}
        animate={{ opacity: 1, y: 0 }}
        className="inline-block font-number"
      >
        {number}
      </motion.span>
    </AnimatePresence>
  )
}
