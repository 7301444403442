import { LoginByPasswordForm } from './login-by-password-form'
// import { LoginByPhoneForm } from './login-by-phone-form'
import { Avatar, AvatarFallback, AvatarImage } from '@/shadcn/components/ui/avatar'
import { cn } from '@/shadcn/utils'
import { Skeleton } from '@/shadcn/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shadcn/components/ui/tabs'
import { RegisterByPasswordForm } from './register-by-password-form'
import { useNavigate } from "react-router-dom"


export function Login() {
  const navigate = useNavigate()

  return (
    <div className='h-[100vh] flex justify-end items-center phone:h-full phone:items-start'>
      <div className=' w-full h-full phone:hidden'></div>
      <div className='h-full py-16  rounded shrink-0 phone:hidden'>
        <div className='h-full w-1 bg-primary rounded animate-in zoom-in'></div>
      </div>
      <div className='w-[32rem] p-16 shrink-0 phone:w-full phone:p-2'>
        <div className='mb-8'>
          <h1 className="flex space-x-2 scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">

            <Avatar className={cn("rounded-lg", {
              // "invert": pathname === '/'
            })} >
              <AvatarImage src='/images/dark-logo.png' alt="Avatar" className="block dark:hidden" />
              <AvatarImage src='/images/light-logo.png' alt="Avatar" className="hidden dark:block" />
              <AvatarFallback>
                <Skeleton className="h-10 w-10 rounded-lg" />
              </AvatarFallback>
            </Avatar>
            <span>
              登录并开始记分
            </span>
          </h1>
          {/* <div className="">
          <Button variant="link" className="text-muted-foreground" onClick={(e) => {
            e.preventDefault()
          }}>
            没有账号? 前往注册
          </Button>
        </div> */}
        </div>

        <Tabs defaultValue="login" className="min-h-[350px]">
          <TabsList>
            <TabsTrigger value="login">登录</TabsTrigger>
            <TabsTrigger value="register">注册</TabsTrigger>
          </TabsList>
          <TabsContent value="login" className="">
            <LoginByPasswordForm callback={() => navigate("/")} />
          </TabsContent>
          <TabsContent value="register" className="">
            <RegisterByPasswordForm callback={() => navigate("/")} />
          </TabsContent>
          {/* <LoginByPhoneForm /> */}
        </Tabs>
      </div>
    </div>
  )
}
