import React, { useState } from 'react'
import { Fullscreen, Maximize, Lightbulb, RotateCcw, X, Loader2 } from 'lucide-react'
import { useNextMatchText } from '@/hooks/use-events'
import { Button } from '@/shadcn/components/ui/button'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { useMedia } from 'react-use'
import { toast } from 'sonner'
import { MatchGraph } from '../match-graph-234'
import { ScreenModal } from '@/shadcn/components/_screen-modal'
import { DialogClose } from '@/shadcn/components/ui/dialog'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'
import { attendeesMatchCount } from './swiss-board'


function QuicklyBar(props) {
  const { event, mutate, feMutate, inScreenModal, matching } = props
  const nextMatchText = useNextMatchText(event)
  const isDesktop = useMedia('(min-width: 450px)')
  const [loading, setLoading] = useState(false)
  const isTournament = event?.ruleTemplateKey === RULE_TEMPLATE_KEY.to1 || event?.ruleTemplateKey === RULE_TEMPLATE_KEY.to2
  const isSwiss = event?.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1
  const matches = event?.matchController?.matches
  const swissMinRound = attendeesMatchCount(event?.attendeeIds?.length)
  const swissCurrentRound = Math.max(...matches.map(i => i.swissProperties?.round)) // 也可以理解为“目前数字最高的轮次”

  return (
    <div className='px-4 pb-4 sticky bottom-0 z-30'>
      <div className='border border-collapse bg-background p-3 flex items-center rounded-[10px]'
      >
        {matching === false && event?.stage === "2-CONFIRM-SEAT" && (
          <div className=''>确认席位中</div>
        )}
        {matching === false && event?.stage === "3-INPROGRESS" && (
          <div className=''>比赛中</div>
        )}
        {matching && event?.stage === "2-CONFIRM-SEAT" && (
          <div className='flex items-center gap-4 relative'>
            <span className='hidden md:block'>拖拽可调整选手席位</span>
            <Button disabled={loading} onClick={async () => {
              setLoading(true)
              await event.setStage("3-INPROGRESS")
              await mutate()
              setLoading(false)
            }}>

              {loading && <Loader2 className="animate-spin w-4 mr-2" />}
              {isDesktop ? "席位确认完毕，正式开始比赛" : "开始比赛"}
            </Button>
          </div>
        )}

        {matching && event?.stage === "3-INPROGRESS" && isTournament && (
          <div className='flex items-center'>
            {nextMatchText ? (
              <Button variant="ghost" className="font-number items-center"
                onClick={() => {
                  props.setHighLightMatchNumber(nextMatchText)
                  feMutate()
                  // todo 判断如果定时器存在，就清除并重新计时，以处理用户连点的情况。
                  setTimeout(() => {
                    props.setHighLightMatchNumber(null)
                    feMutate()
                  }, 2000)
                }}
              >
                <Lightbulb className='w-4 mr-1' />
                <div>当前比赛: #{nextMatchText}</div>
              </Button>
            ) : (
              <div>
                <span className='mr-2'>全部场次已完成记分</span>
                <Button onClick={async () => {
                  await event.setStage("4-FINISHED")
                  mutate()
                }}>确认比赛结束，查看排名表</Button>
              </div>
            )}
          </div>
        )}

        {matching && event?.stage === "3-INPROGRESS" && isSwiss && (
          <div className='flex items-center'>
            <span className='mr-2'>共 {swissMinRound} 轮比赛，当前第 {swissCurrentRound} 轮</span>

            {swissMinRound > swissCurrentRound && !nextMatchText && (
              <Button disabled={loading} onClick={async () => {
                setLoading(true)
                await event.genSwissNextRoundMatches()
                await mutate()
                setLoading(false)
              }}>
                {loading && <Loader2 className="animate-spin w-4 mr-2" />}
                <span >开启新一轮对阵</span>
              </Button>
            )}
            {swissMinRound === swissCurrentRound && !nextMatchText && (
              <Button disabled={loading} onClick={async () => {
                setLoading(true)
                await event.setStage("4-FINISHED")
                await mutate()
                setLoading(false)
              }}>
                {loading && <Loader2 className="animate-spin w-4 mr-2" />}
                <span >确认比赛结束，查看排名表</span>
              </Button>
            )}
          </div>
        )}

        {event?.stage === "4-FINISHED" && (
          <div className='pl-2'>比赛已结束。</div>
        )}

        <div className="ml-auto flex">
          <Tooltip label="刷新">
            <Button size="icon" variant="ghost" onClick={() => {
              toast("已刷新", { closeButton: true })
              mutate()
            }}>
              <RotateCcw className='w-5' />
            </Button>
          </Tooltip>

          {isDesktop && !inScreenModal && !props.screenFull && (
            <>
              <ScreenModal
                triggerButton={(
                  <Tooltip label="浏览器全屏">
                    <Button size="icon" variant="ghost" >
                      <Fullscreen className='w-5' />
                    </Button>
                  </Tooltip>
                )}
              >
                <MatchGraph event={event} mutate={mutate} inScreenModal={true} />
              </ScreenModal>
              <Tooltip label={"全屏显示"}>
                <Button size="icon" variant="ghost" className="" onClick={() => {
                  props.toggleFullscreen()
                }}>
                  <Maximize className='w-5' />
                </Button>
              </Tooltip>
            </>
          )}

          {inScreenModal && !props.screenFull && (
            <Tooltip label={"退出浏览器全屏"}>
              <DialogClose asChild>
                <Button size="icon" variant="ghost" className="" >
                  <X className='w-5' />
                </Button>
              </DialogClose>
            </Tooltip>
          )}
          {props.screenFull && (
            <Tooltip label={"退出全屏显示"}>
              <Button size="icon" variant="ghost" onClick={() => props.toggleFullscreen()}>
                <X className='w-5' />
              </Button>
            </Tooltip>
          )}
        </div>

      </div>
    </div>
  )
}

export default QuicklyBar
