import React from 'react'
import { Dot, Share2 } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button'
import { toast } from 'sonner'
import { Link } from 'react-router-dom'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'
import { useCurrentUser } from '@/hooks/use-session'
import { post } from '@/utils/laf-db'


export function RegistrationStage({ event, reload, matching }) {
  const { data: account } = useCurrentUser()
  const alreadyRegisteredYourSelf = event?.attendees?.some(i => {
    return i.asAccount === true && i.owner === account?.id
  })

  const copyUrl = () => {
    navigator.clipboard.writeText(`${window.location.origin}/registration?eid=${event.id}`)
    toast("已复制报名链接", { duration: 1000 })
  }

  const start = async () => {
    let { result, message } = event.verifyMatchGenerate()
    if (result === true) {
      // await event.generateMatches()
      await event.generateMatches()
      await event.setStage("2-CONFIRM-SEAT")
      reload()
    } else {
      toast(message, { closeButton: true })
    }
  }

  const unregisterForMyself = async () => {
    let self = event?.attendees.find(i => {
      return i.asAccount === true && i.owner === account?.id
    })
    let url = `/events/delete-attendees`
    await post(url, { eventId: event.id, attendeeIds: [self.id] })
    await reload()
    toast("已为自己取消报名", { duration: 1000 })
  }

  return (
    <div className='flex-center flex-col w-full h-full p-4 min-h-[400px]'>
      <div className=''>
        <div>请确认比赛规则与参赛选手</div>
        <div className='flex'>
          <Dot className="shrink-0" />
          <span>完全随机分配对手</span>
        </div>
        <div className='flex'>
          <Dot className="shrink-0" />
          <span>
            <span>比赛开始后，将无法再添加选手，</span>
            {event?.attendees?.length > 0 && <span>当前共 {event?.attendees?.length} 位选手参赛</span>}
            {event?.attendees?.length === 0 && <span>当前尚无选手参赛</span>}
          </span>
        </div>

        {event.ruleTemplateKey === RULE_TEMPLATE_KEY.to2 && (
          <div className='flex'> <Dot className="shrink-0" />双败赛如有必要，可能会有额外一场 Reset 比赛</div>
        )}
      </div>

      {matching && (
        <Button className="mt-10" onClick={start} disabled={event?.attendees?.length === 0}>
          确认所有选手已报名，开始分配选手席位
        </Button>
      )}

      {event.visibility === "public" && event.registrationPage && (
        <div className="mt-10 flex gap-2">
          {alreadyRegisteredYourSelf ? (
            <Button variant="secondary" onClick={unregisterForMyself}>
              取消报名
            </Button>
          ) : (
            <Link to={`/registration?eid=${event.id}`}>
              <Button>我要报名</Button>
            </Link>
          )}

          <Button variant="secondary" onClick={copyUrl}>
            <Share2 className="h-4 w-4 mr-2" />
            <span>分享报名链接</span>
          </Button>
        </div>
      )}

    </div>

  )
}

