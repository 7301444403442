// 第二层，分析规则和模式，生成 dataset，给 peg board，得到对阵表视图给 detail
import React, { useRef, useState } from 'react'
import { cn } from '@/shadcn/utils'
import { useBoolean, useFullscreen } from 'react-use'
import QuicklyBar from './graph-blocks/quickly-bar-in-graph'
import { SwissBoard } from './graph-blocks/swiss-board'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'
import { TournamentGraph } from '@/components/match-table/tournament'
import { DndContext } from '@dnd-kit/core'
import { post } from '@/utils/laf-db'


const spacerX = (x) => x * 290
const spacerY = (y) => y * 100


export const getMatchesContentSize = (matches) => {
  if (!matches) {
    return {
      width: 300,
      height: 300
    }
  }
  let mostX = Math.max(...matches.map(i => i.x))
  let mostY = Math.max(...matches.map(i => i.y))
  return {
    width: spacerX(mostX + 1) + 60,
    height: spacerY(mostY + 1) + 160 // 这 160 人工修正，为了操作栏空间
  }
}

export function MatchGraph({ event, mutate, matching, inScreenModal }) {
  const [, setHighLightMatchNumber] = useState(null)
  const [draggingProps, setDraggingProps] = useState(null)
  const [dragEndLoading, setDragEndLoading] = useState(null)

  const board = useRef(null)
  const matches = event?.matchController?.matches
  // let { width: contentWidth, height: contentHeight } = getMatchesContentSize(matches)
  // 全屏相关
  const graphRef = useRef(null)
  const [fs, toggleFullscreen] = useBoolean(false) // 占满显示器
  const screenFull = useFullscreen(graphRef, fs, { onClose: () => toggleFullscreen(false) })


  const reload = async () => {
    await mutate()
    await board.current?.reload()
  }
  // let dataset = useMatchesDataset(event, matches, (node) => {
  //   // let number = i.tournamentProperties?.number
  //   // let flash = number === highLightMatchNumber && event?.stage === "3-INPROGRESS"
  //   let i = matches.find(m => m.id === node.key)
  //   return (
  //     <div key={i.id}
  //       data-match-id={i.id}
  //       className={cn(
  //         "absolute duration-1000 rounded-sm",
  //       )}
  //     >
  //       <MatchSmallCard
  //         stage={event?.stage}
  //         match={i}
  //         reload={reload}
  //       />
  //     </div>
  //   )
  // })
  const r = event.ruleTemplateKey
  const isSwiss = r === RULE_TEMPLATE_KEY.sw1
  const isTournament = [RULE_TEMPLATE_KEY.to1, RULE_TEMPLATE_KEY.to2, RULE_TEMPLATE_KEY.to2_no_champion].includes(r)


  const handleSeatChange = async (e) => {
    setDraggingProps(null)
    let aMatch = e.active?.data?.current?.match
    let aPlayer = e.active?.data?.current?.player
    let bMatch = e.over?.data?.current?.match
    let bPlayer = e.over?.data?.current?.player
    if (!aMatch || !aPlayer || !bMatch || !bPlayer) return
    setDragEndLoading({ aMatch, aPlayer, bMatch, bPlayer })


    const aIndex = aMatch.scoreboard.findIndex(i => i.playerId === aPlayer.id)
    const bIndex = bMatch.scoreboard.findIndex(i => i.playerId === bPlayer.id)
    aMatch.scoreboard.splice(aIndex, 1, { playerId: bPlayer.id, value: "0" })
    bMatch.scoreboard.splice(bIndex, 1, { playerId: aPlayer.id, value: "0" })
    // 接口是非覆盖式，只需传入修改的字段
    const ma = { _id: aMatch.id, scoreboard: aMatch.scoreboard }
    const mb = { _id: bMatch.id, scoreboard: bMatch.scoreboard }


    // 这里复制会导致同场次调整 scoreboard 变成重复的，尚未知是什么 bug
    // const aScoreboard = cloneDeep(aMatch.scoreboard)
    // const bScoreboard = cloneDeep(bMatch.scoreboard)
    // const aIndex = aScoreboard.findIndex(i => i.playerId === aPlayer.id)
    // const bIndex = bScoreboard.findIndex(i => i.playerId === bPlayer.id)
    // aScoreboard.splice(aIndex, 1, { playerId: bPlayer.id, value: "0" })
    // bScoreboard.splice(bIndex, 1, { playerId: aPlayer.id, value: "0" })
    // const ma = { _id: aMatch.id, scoreboard: aScoreboard }
    // const mb = { _id: bMatch.id, scoreboard: bScoreboard }
    // console.log('修改之后的', ma)
    // console.log('修改之后的', mb)

    await post('/matches/set', { matches: [ma, mb] })
    await reload()
    setDragEndLoading(null)
  }

  const handleSeatDragStart = (e) => {
    setDraggingProps({ matchId: e.active?.data?.current?.match.id })
  }

  return (
    <div ref={graphRef} className={cn({ "overflow-scroll": screenFull })}>
      <DndContext onDragEnd={handleSeatChange} onDragStart={handleSeatDragStart}>
        <div className={cn(
          "w-full relative p-1",
          { "min-h-[calc(100dvh_-_6rem)]": screenFull || inScreenModal },
        )}>
          {matches && isSwiss && (
            <SwissBoard
              dragEndLoading={dragEndLoading}
              event={event}
              reload={reload}
              matching={matching} />
          )}
          {matches && isTournament && (
            <TournamentGraph
              dragEndLoading={dragEndLoading}
              draggingProps={draggingProps}
              matches={matches}
              screenFull={screenFull || inScreenModal}
              controllerProps={{ reload, stage: event.stage }} />
          )}
        </div>
      </DndContext>
      <QuicklyBar
        event={event}
        mutate={reload}
        feMutate={() => board.current?.reload()}
        screenFull={screenFull}
        toggleFullscreen={toggleFullscreen}
        inScreenModal={inScreenModal}
        setHighLightMatchNumber={setHighLightMatchNumber}
        matching={matching}
      />
    </div>
  )
}

