import { Network, LogInIcon, Users2, Swords, MenuSquare, Book, FlameKindling } from "lucide-react"
import { Avatar, AvatarFallback, AvatarImage } from "@/shadcn/components/ui/avatar"
import { Separator } from '@/shadcn/components/ui/separator'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { ThemeToggle } from '@/shadcn/components/_theme-toggle'
import { useCurrentUser } from '@/hooks/use-session'
import { useLocation, Link } from 'react-router-dom'
import { Skeleton } from '@/shadcn/components/ui/skeleton'
import { Sheet, SheetContent, SheetTrigger } from "@/shadcn/components/ui/sheet"
import { useMedia } from 'react-use'
import { useState } from "react"


const NavRouters = ({ close }) => {
  let { data: account } = useCurrentUser()
  let { pathname } = useLocation()

  return (
    <div className={cn("flex flex-col mt-2")}>
      <Link to="/" onClick={close} className="flex items-center">
        <Avatar className={cn("rounded-lg phone:w-16 phone:h-16", {
          // "invert": pathname === '/'
        })} >
          <AvatarImage src='/images/light-logo.png' alt="Avatar" className="block dark:hidden" />
          <AvatarImage src='/images/dark-logo.png' alt="Avatar" className="hidden dark:block" />
          <AvatarFallback>
            <Skeleton className="h-10 w-10 rounded-lg" />
          </AvatarFallback>
        </Avatar>
        <div className="hidden phone:block ml-2 ext-4xl font-extrabold tracking-tight">
          Saing Score Recorder
        </div>
      </Link>

      <Separator className="my-4" />

      <Link to="/events" className="flex items-center" onClick={close}>
        <Button variant="outline" size="icon" className={cn(
          "flex-shrink-0",
          { "invert": pathname.includes('/events') }
        )} >
          <Network className="h-4 w-4 rotate-90" />
        </Button>
        <div className="hidden ml-2 phone:block">Events</div>
      </Link>

      <Link to="/communities" className="hidden items-center mt-4" onClick={close}>
        <Button variant="outline" size="icon" className={cn(
          { "invert": pathname.includes('/communities') || pathname.includes('/community') }
        )} >
          <FlameKindling className="h-4 w-4" />
        </Button>
        <div className="hidden ml-2 phone:block">Community</div>
      </Link>

      {account && (
        <Link to="/matches" className="flex items-center mt-4" onClick={close}>
          <Button variant="outline" size="icon" className={cn({
            "invert": pathname.includes('/matches'),
          })} >
            <Swords className="h-4 w-4" />
          </Button>
          <div className="hidden ml-2 phone:block">Matches</div>
        </Link>
      )}

      {account && (
        <Link to="/players" className="flex items-center mt-4" onClick={close}>
          <Button variant="outline" size="icon" className={cn({
            "invert": pathname.includes('/players'),
          })} >
            <Users2 className="h-4 w-4" />
          </Button>
          <div className="hidden ml-2 phone:block">Players</div>

        </Link>
      )}

      <Link to="/docs" className="flex items-center mt-4" onClick={close}>
        <Button variant="outline" size="icon" className={cn({
          "invert": pathname.includes('/docs'),
        })} >
          <Book className="h-4 w-4" />
        </Button>
        <div className="hidden ml-2 phone:block">Docs</div>

      </Link>

    </div>
  )
}

const SettingMenu = ({ close: closeSheet }) => {
  let { data: account } = useCurrentUser()
  return (
    <div className={cn(
      "flex justify-end items-center",
      "flex-col mt-auto mb-2 space-y-4",
      "phone:flex-row-reverse phone:space-y-0 phone:mb-0",
    )}>
      <div className="block phone:hidden">
        <ThemeToggle />
      </div>

      <div>
        {!account && (
          <Link to="/login">
            <Button variant="secondary" size="icon" className="rounded-full">
              <LogInIcon className="h-4 w-4" />
            </Button>
          </Link>
        )}

        {account &&
          <Link to="/account/settings" onClick={closeSheet} className="flex items-center">
            <Avatar className="rounded-lg cursor-pointer">
              <AvatarImage src={account.avatar} alt="Avatar" className="object-cover" />
              <AvatarFallback>{account.avatarFallback}</AvatarFallback>
            </Avatar>
            <div className="hidden phone:block ml-2">{account.displayName}</div>
          </Link>
        }
      </div>
    </div>
  )
}


export function NavBar() {
  const isDesktop = useMedia('(min-width: 450px)') // 
  let [open, setOpen] = useState(false)


  return (
    <nav
      className={cn(
        "flex flex-col flex-shrink-1 p-2 bg-background z-40",
        "fixed top-0 left-0 w-[58px] h-screen border-r",
        "phone:top-auto phone:bottom-0 phone:w-full phone:h-[58px] phone:border-t phone:border-r-0",
      )}
      aria-label='Global'
    >

      {isDesktop ? (
        <>
          <NavRouters />
          <SettingMenu />
        </>
      ) : (
        <div className="flex justify-between">
          <Sheet open={open} onOpenChange={setOpen} >
            <SheetTrigger asChild>
              <Button size="icon" variant="outline" >
                <MenuSquare className="w-4 h-4" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left">
              <div className="flex flex-col flex-shrink-1 h-full">
                <NavRouters close={() => setOpen(false)} />
                <SettingMenu close={() => setOpen(false)} />
              </div>
            </SheetContent>
          </Sheet>

          <ThemeToggle />
        </div>
      )}

    </nav >
  )
}


