import React from 'react'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { Confirm } from '@/shadcn/components/_confirm'
import { Unit } from '@/pages/events/match-card-unit/unit'


export const attendeesMatchCount = (attendeesCount) => {
  if (attendeesCount < 5) {
    return null
  } else if (attendeesCount <= 8) {
    return 3
  } else if (attendeesCount <= 16) {
    return 4
  } else if (attendeesCount <= 32) {
    return 5
  } else if (attendeesCount <= 64) {
    return 6
  } else if (attendeesCount <= 128) {
    return 7
  } else if (attendeesCount <= 256) {
    return 8
  }
  return null
}

// 传入 matches 数据，返回能被瑞士轮正确渲染的数据：rounds
const useMatchesToSwissRound = (matches) => {
  let rounds = []
  let maxRound = Math.max(...matches.map(i => i.swissProperties?.round))
  for (let i = 1; i <= maxRound; i++) {
    rounds.push({
      round: i,
      matches: matches.filter(m => m.swissProperties?.round === i).sort((a, b) => a.swissProperties?.number - b.swissProperties?.number)
    })
  }
  return rounds
}


export function SwissBoard({ event, reload, matching, dragEndLoading }) {
  const matches = event?.matchController?.matches
  let rounds = useMatchesToSwissRound(matches)
  let someUnfinished = matches.some(i => i.stage !== "finished")
  let minRoundNumber = attendeesMatchCount(event?.attendeeIds?.length)
  let currentRound = Math.max(...rounds.map(i => i.round)) // 也可以理解为“目前数字最高的轮次”

  // const handleSeatChange = async (e) => {
  //   let aMatch = e.active?.data?.current?.match
  //   let aPlayer = e.active?.data?.current?.player
  //   let bMatch = e.over?.data?.current?.match
  //   let bPlayer = e.over?.data?.current?.player
  //   if (!aMatch || !aPlayer || !bMatch || !bPlayer) return

  //   const aIndex = aMatch.scoreboard.findIndex(i => i.playerId === aPlayer.id)
  //   const bIndex = bMatch.scoreboard.findIndex(i => i.playerId === bPlayer.id)

  //   aMatch.scoreboard.splice(aIndex, 1, { playerId: bPlayer.id, value: "0" })
  //   bMatch.scoreboard.splice(bIndex, 1, { playerId: aPlayer.id, value: "0" })

  //   // 接口是非覆盖式，只需传入修改的字段
  //   const ma = { _id: aMatch.id, scoreboard: aMatch.scoreboard }
  //   const mb = { _id: bMatch.id, scoreboard: bMatch.scoreboard }

  //   await post('/matches/set', { matches: [ma, mb] })
  //   await reload()
  // }
  // 瑞士轮暂时没有其他视图
  return (
    // <DndContext onDragEnd={handleSeatChange}>
    <div className='h-full pb-36 overflow-scroll no-scrollbar'>
      {rounds.map(r => {
        let thisRoundMatchesCount = r.matches.length
        let thisRoundRecordedCount = r.matches.filter(i => i.stage === "finished").length
        return (
          <div key={r.round} className='mb-12'>
            <div className='py-1 pl-3 my-4 mx-8 text-muted-foreground bg-secondary rounded flex items-center'>
              <span>第 {r.round} 轮</span>
              {matching && currentRound === r.round && event.stage !== "4-FINISHED" && (
                <Confirm
                  confirmText="是的，删除本轮对局"
                  title="确定删除本轮对局吗？"
                  description={`本轮共 ${thisRoundMatchesCount} 场比赛记录将被删除，当前标记 ${thisRoundRecordedCount} 场已完赛。`}
                  triggerButton={(
                    <Button variant="ghost" className="ml-auto">
                      删除本轮对局
                    </Button>
                  )}

                  confirmAction={async () => {
                    await event?.removeSwissMatchesByRound?.(r.round)
                    await reload()
                  }}
                >
                </Confirm>
              )}
            </div>
            <div className='flex px-8 w-full flex-wrap gap-y-10 gap-x-12'>
              {r.matches.map(i => {
                return (
                  <div key={i.id}
                    data-match-id={i.id}
                    className={cn("rounded-sm relative")}
                  >
                    <Unit
                      dragEndLoading={dragEndLoading}
                      stage={event?.stage}
                      match={i}
                      reload={reload}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}

      <div className='flex mt-16 justify-start ml-8'>
        {matching && event?.stage === "3-INPROGRESS" && (currentRound < minRoundNumber) && (
          <Button
            variant="secondary"
            disabled={someUnfinished}
            onClick={async () => {
              await event.genSwissNextRoundMatches()
              await reload()
            }}>
            开启新一轮对阵
          </Button>
        )}
      </div>
    </div>
    // </DndContext>
  )
}
