import React, { useState } from 'react'
import { useCurrentUser, useResourceUserMatching } from '@/hooks/use-session'
import { cn } from '@/shadcn/utils'
import { FixedScoreView } from '@/pages/matches/fixed-score-view'
import { SeatDroppable } from './unit-seat-droppable'
import { SeatDraggable } from './unit-seat-draggable'
import { UnitButtons } from './unit-buttons'
import { useMedia } from 'react-use'


// import { createGlobalState } from "react-use"
// import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
// const useGlobalHoverId = createGlobalState("")

// function CardScore({ p, dependents, win, plusScore, decreaseScore, matching, match, reload, allowSeatAdjustment }) {
//   let [value, setValue] = useGlobalHoverId()
//   const adder = (
//     <>
//       <div onClick={() => decreaseScore(p)}
//         className={cn(
//           "bg-background opacity-0 hover:opacity-60",
//           "absolute cursor-pointer ml-auto flex justify-center items-center transition-all",
//           "rounded-lg border-2 border-primary w-5 h-5 right-9 translate-y-[5px]",
//           {
//             "group-hover/line:opacity-100": true,
//           })}>
//         -
//       </div>
//       <div onClick={() => plusScore(p)}
//         className={cn("bg-[#c7c7c7] dark:bg-[#4c4c4c] text-foreground hover:opacity-60",
//           "cursor-pointer ml-auto w-8 flex justify-center items-center flex-shrink-0",
//           "font-number")}>
//         {p?.value}
//       </div>
//     </>
//   )

//   const staticScoreboard = (
//     <div
//       className={cn("bg-[#c7c7c7] dark:bg-[#4c4c4c] text-foreground font-number",
//         "ml-auto w-8 flex justify-center items-center flex-shrink-0")}>
//       {p?.value}
//     </div>
//   )

//   if (p?.player) {
//     let { player } = p
//     return (
//       <div className='flex h-[30px] bg-input group/line'
//         onMouseEnter={() => { setValue(p.playerId) }}
//         onMouseLeave={() => { setValue("") }}
//       >
//         <div className='w-8 flex justify-center items-center flex-shrink-0'>
//           <PlayerAvatar player={player} className="w-4 h-4" />
//         </div>

//         <div className={cn(
//           'pl-2 w-full flex items-center text-sm font-medium truncate',
//           { "bg-winner border-winner": win },
//           { "underline underline-offset-2 bg-yellow-300 dark:bg-yellow-700": value === p.playerId },
//         )}>
//           {player?.name}
//         </div>
//         {matching ? adder : staticScoreboard}
//       </div>
//     )
//   } else {
//     return (
//       <div className='flex h-[30px] bg-secondary'>
//         <div className='pl-2 w-full flex items-center text-[#787A80] font-mono text-xs'>
//           {dependents ? dependents.toUpperCase() : "暂无选手参赛"}
//         </div>
//       </div>
//     )
//   }
// }

// MatchSmallCard 还是应该接受一组 controller 来控制状态，而不是在组件内部自行判断

export function Unit({ match, reload, stage, dragEndLoading }) {
  let { data: account } = useCurrentUser()
  let { matching: resourceMatching } = useResourceUserMatching(account, match)
  let [loading, setLoading] = useState(false)
  const [openScoreboard, setOpenScoreboard] = useState(false)
  const isPhone = useMedia('(max-width: 450px)')

  let [p1, p2] = match.scoreboard

  let stage3 = stage === "3-INPROGRESS"
  let matching = stage3 ? resourceMatching : false
  let showButtons = match.scoreboard.length > 0 && stage3

  return (
    <div className={cn('w-[200px] h-[62px] overflow-hidden select-none group', {
      "spin": loading,
    })} >
      <div className={cn(
        'font-mono bg-secondary text-secondary-foreground text-xs px-1.5 h-[24px] be-center-vertically flex justify-center items-center rounded-l transition-all',
        // 'absolute -translate-x-[99%] z-20', // 原始版本，基本不动
        // 'absolute z-20 -translate-x-[99%] group-hover:translate-x-0', // 用于隐藏
        'absolute z-20 -translate-x-[99%]',
        {
          "group-hover:z-10 group-hover:rounded group-hover:translate-x-0 group-hover:translate-y-9": isPhone === false,
          "bg-winner opacity-50": match?.stage === "in progress"
        },
      )}>
        <span>{match?.number}</span>
      </div>
      {/* <div className={cn(
        'absolute font-mono rounded bg-secondary text-secondary-foreground h-[24px] be-center-vertically text-xs p-1 transition-all',
        'z-20 group-hover:right-0 group-hover:translate-y-9',
      )}>
        <span>{match?.stage.slice(0, 2).toUpperCase()}</span>
      </div> */}
      <div className={cn(
        'cursor-default bg-secondary/50 border z-10 absolute w-[180px] h-4 rounded-xl translate-x-2 transition-all',
        "group-hover:-translate-y-2 group-hover:-translate-x-2 group-hover:w-[216px] group-hover:h-[6.5rem]",
        "phone:-translate-y-2 phone:w-[240px] phone:h-[6.5rem] phone:border-none phone:bg-secondary/0"
      )}>
        {showButtons && (
          <UnitButtons
            match={match}
            setLoading={setLoading}
            setOpenScoreboard={setOpenScoreboard}
            reload={reload} />
        )}
      </div>
      <div className={cn(
        "flex absolute z-20 flex-col h-[62px] justify-between bg-background rounded-lg",
        {
          "cursor-default": stage === "4-FINISHED",
        }
      )}>
        <SeatDroppable player={p1?.player} match={match}>
          <SeatDraggable
            dragEndLoading={dragEndLoading}
            player={p1?.player}
            match={match}
            reload={reload}
            seatIndex={1}
            matching={resourceMatching}
            className="top-0 rounded-t-lg" />
        </SeatDroppable>
        <div className='bg-background h-0.5'></div>
        <SeatDroppable player={p2?.player} match={match}>
          <SeatDraggable
            dragEndLoading={dragEndLoading}
            player={p2?.player}
            match={match}
            reload={reload}
            seatIndex={2}
            matching={resourceMatching}
            className="top-[32px] rounded-b-lg" />
        </SeatDroppable>
      </div>

      {openScoreboard && (
        <FixedScoreView
          match={match}
          mutate={reload}
          disabled={!matching}
          close={() => setOpenScoreboard(false)} />
      )}

    </div >
  )
}
