import React from 'react'
import { cn } from '@/shadcn/utils'
import { useDroppable } from '@dnd-kit/core'

export function SeatDroppable({ match, player, children, className }) {
  const { setNodeRef, isOver } = useDroppable({
    id: `drag_${match?.id}_${player?.name}_${player?.id}`,
    data: { match, player },
    disabled: !player?.name,
  })

  return (
    <div ref={setNodeRef} className={cn({ 'opacity-25 z-10': isOver })}>
      {children}
    </div>
  )
}
