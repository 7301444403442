import React from 'react'
import { Check, Eraser, MoveDiagonal, RotateCw, Settings } from 'lucide-react'
import { useCurrentUser, useResourceUserMatching } from '@/hooks/use-session'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { Modal } from '@/shadcn/components/_modal'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { MatchSettings } from '@/pages/matches/match-settings'

// MatchSmallCard 还是应该接受一组 controller 来控制状态，而不是在组件内部自行判断
export function UnitButtons({ match, reload, setOpenScoreboard, setLoading }) {
  let { data: account } = useCurrentUser()
  let { matching: resourceMatching } = useResourceUserMatching(account, match)


  let matching = resourceMatching


  const resetScore = async () => {
    setLoading(true)
    await match.resetScore()
    await match.markStage("in progress")
    await reload()
    setLoading(false)
  }

  const finishMatch = async () => {
    setLoading(true)
    await match.playersPromote()
    await match.markStage("finished")
    await reload()
    setLoading(false)
  }

  const unfinished = async () => {
    setLoading(true)
    await match.markStage("in progress")
    await reload()
    setLoading(false)
  }

  return (
    <div className={cn(
      'h-[40px] w-[200px]',
      "transition-all opacity-0 flex items-center justify-center px-2 absolute z-10",
      "translate-y-6 delay-100",
      "group-hover:bottom-5 group-hover:w-full group-hover:opacity-100",
      "phone:opacity-100",
      "phone:bottom-5 translate-y-6"
    )}>
      {matching && (
        <Modal
          title={"Scoreboard"}
          triggerButton={(
            <Tooltip label="Settings" side="left" >
              <Button size="icon" className="w-6 h-6 mx-1">
                <Settings className="h-4 w-4" />
              </Button>
            </Tooltip>
          )}
          render={({ close }) => {
            return (
              <MatchSettings
                hiddenDelete={true}
                disabledChangePlayer={true}
                match={match}
                mutate={async () => {
                  await reload()
                  close()
                }}
              />
            )
          }}
        />
      )}


      <Button size="icon" className="w-6 h-6 mx-1 hidden" onClick={() => setOpenScoreboard(true)}>
        <MoveDiagonal className="h-4 w-4" />
      </Button>

      {matching && match.stage !== "finished" && (
        <Button onClick={() => resetScore()} size="icon" className="w-6 h-6 mx-1">
          <Eraser className="h-4 w-4" />
        </Button>
      )}

      {matching && match.stage !== "finished" && (
        <Tooltip label="Finished" side="right" >
          <Button onClick={() => finishMatch()} size="icon" className="w-6 h-6 mx-1">
            <Check className="h-4 w-4" />
          </Button>
        </Tooltip>
      )}
      {matching && match.stage === "finished" && (
        <Tooltip label="Unfinished" side="right" >
          <Button onClick={() => unfinished()} size="icon" className="w-6 h-6 mx-1">
            <RotateCw className="h-4 w-4" />
          </Button>
        </Tooltip>
      )}
    </div >



  )
}
